@import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap');

body {
    font-family: "work sans", serif;
    min-height: 100vh;
}

html {
    font-size: 100%;
}

.forms-section {
    background: rgb(34, 193, 195);
    background: linear-gradient(135deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.section-title {
    font-size: 32px;
    letter-spacing: 1px;
    color: #fff;
}

.forms {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
}

.form-wrapper {
    animation: hideLayer .3s ease-out forwards;
}

.form-wrapper.is-active {
    animation: showLayer .3s ease-in forwards;
}

@keyframes showLayer {
    50% {
        z-index: 1;
    }
    100% {
        z-index: 1;
    }
}

@keyframes hideLayer {
    0% {
        z-index: 1;
    }
    49.999% {
        z-index: 1;
    }
}

.switcher {
    position: relative;
    cursor: pointer;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    text-transform: uppercase;
    font-family: inherit;
    font-size: 16px;
    letter-spacing: .5px;
    color: #999;
    background-color: transparent;
    border: none;
    outline: none;
    transform: translateX(0);
    transition: all .3s ease-out;
}

.form-wrapper.is-active .switcher-login {
    color: #fff;
    transform: translateX(90px);
}

.form-wrapper.is-active .switcher-signup {
    color: #fff;
    transform: translateX(-90px);
}

.underline {
    position: absolute;
    bottom: -5px;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    width: 100%;
    height: 2px;
}

.underline::before {
    content: '';
    position: absolute;
    top: 0;
    left: inherit;
    display: block;
    width: inherit;
    height: inherit;
    background-color: currentColor;
    transition: transform .2s ease-out;
}

.switcher-login .underline::before {
    transform: translateX(101%);
}

.switcher-signup .underline::before {
    transform: translateX(-101%);
}

.form-wrapper.is-active .underline::before {
    transform: translateX(0);
}

.form {
    overflow: hidden;
    min-width: 260px;
    margin-top: 50px;
    padding: 30px 25px;
    border-radius: 5px;
    transform-origin: top;
}

.form-login {
    animation: hideLogin .3s ease-out forwards;
}

.form-wrapper.is-active .form-login {
    animation: showLogin .3s ease-in forwards;
}

@keyframes showLogin {
    0% {
        background: rgba(255, 255, 255, 0.5);
        transform: translate(40%, 10px);
    }
    50% {
        transform: translate(0, 0);
    }
    100% {
        background-color: #fff;
        transform: translate(35%, -20px);
    }
}

@keyframes hideLogin {
    0% {
        background-color: #fff;
        transform: translate(35%, -20px);
    }
    50% {
        transform: translate(0, 0);
    }
    100% {
        background: rgba(255, 255, 255, 0.5);
        transform: translate(40%, 10px);
    }
}

.form-signup {
    animation: hideSignup .3s ease-out forwards;
}

.form-wrapper.is-active .form-signup {
    animation: showSignup .3s ease-in forwards;
}

@keyframes showSignup {
    0% {
        background: rgba(255, 255, 255, 0.5);
        transform: translate(-40%, 10px) scaleY(.8);
    }
    50% {
        transform: translate(0, 0) scaleY(.8);
    }
    100% {
        background-color: #fff;
        transform: translate(-35%, -20px) scaleY(1);
    }
}

@keyframes hideSignup {
    0% {
        background-color: #fff;
        transform: translate(-35%, -20px) scaleY(1);
    }
    50% {
        transform: translate(0, 0) scaleY(.8);
    }
    100% {
        background: rgba(255, 255, 255, 0.5);
        transform: translate(-40%, 10px) scaleY(.8);
    }
}

.form fieldset {
    position: relative;
    opacity: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: all .3s ease-out;
}

.form-login fieldset {
    transform: translateX(-50%);
}

.form-signup fieldset {
    transform: translateX(50%);
}

.form-wrapper.is-active fieldset {
    opacity: 1;
    transform: translateX(0);
    transition: opacity .4s ease-in, transform .35s ease-in;
}

.form legend {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
}

.input-block {
    margin-bottom: 20px;
}

.input-block label {
    font-size: 14px;
    color: #fff;
}

.input-block input {
    display: block;
    width: 100%;
    margin-top: 8px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
    line-height: 40px;
    color: #3b4465;
    background: #fff;
    border: 1px solid #cddbef;
    border-radius: 10px;
}

.form [type='button'] {
    opacity: 0;
    display: block;
    min-width: 120px;
    margin: 30px auto 10px;
    font-size: 18px;
    line-height: 40px;
    border-radius: 25px;
    border: none;
    transition: all .3s ease-out;
}

.form-wrapper.is-active .form [type='button'] {
    opacity: 1;
    transform: translateX(0);
    transition: all .4s ease-in;
}

.btn-login {
    color: #196400;
    background: #fbfdff;
    box-shadow: inset 0 0 0 2px #196400;
    transform: translateX(-30%);
}

.btn-signup {
    color: #196400;
    background: #fbfdff;
    box-shadow: inset 0 0 0 2px #196400;
    transform: translateX(30%);
}


.profile-menu {
    position: relative;
    display: inline-block;
}

.profile-menu__button {
    background-color: #fff;
    padding: 8px 16px;
    cursor: pointer;
    pointer-events: all;
    border-radius: 15px;
}

.profile-menu__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    list-style-type: none;
    padding: 0;
}

.profile-menu__item {
    padding: 8px 16px;
    cursor: pointer;
}

.profile-menu__item:hover {
    background-color: #f0f0f0;
}

.MuiInputBase-adornedEnd input#rfid {
    cursor: not-allowed !important;
}

.table-container {
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.compact-table {
    width: 100%;
    border-radius: 10px;
    border-collapse: separate;
}

.compact-table th,
.compact-table td {
    border: 1px solid #ddd;
    padding: 12px;
}

.compact-table th {
    background-color: #1f660e;
    color: white;
    text-align: left;
    font-size: 12px;
}

.compact-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.compact-table th:first-child,
.compact-table td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.compact-table th:last-child,
.compact-table td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
